import { useModal } from '@ebay/nice-modal-react';
import { Plus, Search } from 'lucide-react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { IRequest } from '@/types.ts';
import TrustPilot from '../TrustPilot.tsx';
import { Button } from '../ui/button.tsx';
import Actions from './Actions.tsx';
import MedicationAvailability from './MedicationAvailability.tsx';
import SMSSubscription from './SMSSubscription.tsx';
import SubscriptionCTA from './SubscriptionCTA.tsx';

interface RequestShowProps {
  request: IRequest;
  setView: (view: string) => void;
  save: (data: any) => void;
}
const RequestShow = ({ request, setView }: RequestShowProps) => {
  const medicationResultsModal = useModal('medication-results-modal');

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 gap-6 lg:grid-cols-3">
        <div className="block space-y-6 lg:hidden">
          <Actions request={request} />
          <Card>
            <CardHeader>
              <CardTitle className="text-xl font-bold">Want to add more pharmacies?</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <p className="text-sm text-muted-foreground">
                  No problem! We can expand your search to include more pharmacies at anytime. Just click the button
                  below and we'll get started.
                </p>
                <Button
                  className="w-full"
                  onClick={() => {
                    setView('edit');
                  }}
                >
                  <Plus className="w-4 h-4 mr-2" /> Add More Pharmacies
                </Button>
              </div>
            </CardContent>
          </Card>
        </div>

        <div className="col-span-1 space-y-8 sm:col-span-2">
          {request.needle_note && (
            <Card>
              <CardHeader>
                <CardTitle>Needle Summary</CardTitle>
                <CardDescription>
                  Please review the notes from Needle and let us know if you have any questions or concerns.
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="text-sm text-gray-600 whitespace-pre-wrap">{request.needle_note}</div>
              </CardContent>
            </Card>
          )}

          <MedicationAvailability request={request} />

          <Card>
            <CardHeader>
              <CardTitle className="flex items-center justify-between text-xl font-bold">
                <span>All Pharmacies</span>

                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => medicationResultsModal.show({ request: request, medication: request.medications[0] })}
                >
                  <Search className="w-4 h-4 mr-2" />
                  View All Pharmacy Results
                </Button>
              </CardTitle>
            </CardHeader>
          </Card>

          <SubscriptionCTA />
        </div>

        <div className="col-span-1 space-y-8">
          <div className="hidden space-y-6 lg:block">
            <Actions request={request} />
            <Card>
              <CardHeader>
                <CardTitle className="text-xl font-bold">Want to add more pharmacies?</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  <p className="text-sm text-muted-foreground">
                    No problem! We can expand your search to include more pharmacies at anytime. Just click the button
                    below and we'll get started.
                  </p>
                  <Button
                    className="w-full"
                    onClick={() => {
                      setView('edit');
                    }}
                  >
                    <Plus className="w-4 h-4 mr-2" /> Add More Pharmacies
                  </Button>
                </div>
              </CardContent>
            </Card>
          </div>

          <SMSSubscription />

          <TrustPilot />
        </div>
      </div>
    </div>
  );
};

export default RequestShow;
