import NiceModal from '@ebay/nice-modal-react';
import LocationModal from '@/components/Location/LocationInput.tsx';
import MedicationModal from '@/components/Modals/Medications/MedicationModal.tsx';
import TaskTransferModal from '../Requests/TaskTransferModal';
import MedicationResultsModal from './MedicationResults/MedicationResultsModal';
import MembershipModal from './Memberships/MembershipModal';

NiceModal.register('medication-modal', MedicationModal);
NiceModal.register('location-modal', LocationModal);
NiceModal.register('medication-results-modal', MedicationResultsModal);
NiceModal.register('membership-modal', MembershipModal);
NiceModal.register('task-transfer-modal', TaskTransferModal);

const ModalRegistration = () => {
  return <></>;
};

export default ModalRegistration;
