import { useEffect } from 'react';
import axios from 'axios';
import posthog from 'posthog-js';
import { useNavigate, useParams } from 'react-router';
import { useSetRecoilState } from 'recoil';
import state from '@/utils/State';
import Loading from '../Loading';

const SSOLink = () => {
  const navigate = useNavigate();
  const { token } = useParams<{ token: string }>();
  const setSessionId = useSetRecoilState(state.sessionId);
  const setCurrentUser = useSetRecoilState(state.currentUser);

  useEffect(() => {
    localStorage.removeItem('Needle');
    if (token) {
      axios
        .post(`${import.meta.env.VITE_NEEDLE_API_URL}/v1/sessions/sso/${token}`)
        .then((response) => {
          if (response && response.data && response.data.token && response.data.user) {
            setSessionId(response.data.token);
            setCurrentUser(response.data.user);

            posthog.capture('sso_sign_in', { account_created: response.data.user.created_at });

            posthog.identify(response.data.user.id, {
              email: response.data.user.email,
              name: response.data.user.name,
              phone: response.data.user.phone,
              created: response.data.user.created_at,
              plan: response.data.user.plan,
            });

            setTimeout(() => {
              window.location.href = `${response.data.user.onboarding_step}`;
            }, 1000);
          } else {
            navigate('/login');
          }
        })
        .catch(() => {
          navigate('/login');
        });
    }
  }, [token]);

  return (
    <div className="text-center">
      <Loading text="Logging you into your account..." />
    </div>
  );
};

export default SSOLink;
