import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { DollarSign, MapPin, PhoneOutgoing, Pill, User } from 'lucide-react';
import posthog from 'posthog-js';
import { BiSupport } from 'react-icons/bi';
import { useNavigate } from 'react-router';
import HeroSupport from '@/assets/hero_support.svg';
import Modal from '@/components/Modals/Modal';
import { Button } from '@/components/ui/button';

const MembershipModal = NiceModal.create(() => {
  const navigate = useNavigate();
  const modal = useModal('membership-modal');

  return (
    <Modal modal={modal} title="Unlock Premium Features">
      <div className="flex flex-col justify-center gap-2 p-4 pb-0">
        <img src={HeroSupport} className="w-full " />

        <div className="text-2xl font-semibold">Enhanced medication management</div>
        <div className="">
          Subscribers get access to additional call types & cheaper rates for worry-free drug management
        </div>
        <div className="flex flex-col gap-3 mx-2 mt-3 mb-12 ">
          <div className="flex flex-row items-center gap-3">
            <Pill className="w-6 h-6" />
            <div className="">Multiple Medications Per Search</div>
          </div>
          <div className="flex flex-row items-center gap-3">
            <MapPin className="w-6 h-6" />
            <div className="">Pharmacy Favoriting</div>
          </div>
          <div className="flex flex-row items-center gap-3">
            <PhoneOutgoing className="w-6 h-6" />
            <div className="">Refill & transfer calls</div>
          </div>
          <div className="flex flex-row items-center gap-3">
            <DollarSign className="w-6 h-6" />
            <div className="">Huge per call savings</div>
          </div>
          <div className="flex flex-row items-center gap-3">
            <User className="w-6 h-6" />
            <div className="">"Request a call" care conceirge</div>
          </div>
          <div className="flex flex-row items-center gap-3">
            <BiSupport className="w-6 h-6" />
            <div className="">SMS Support Line</div>
          </div>
        </div>

        <Button
          onClick={() => {
            posthog.capture('membership_cta_clicked');
            navigate('/memberships');
            modal.hide();
          }}
        >
          Join now and access unlimited calls
        </Button>
      </div>
    </Modal>
  );
});

export default MembershipModal;
