import Location from '@/components/Location/Location.tsx';
import RequestSummary from '@/components/Requests/RequestSummary.tsx';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Textarea } from '@/components/ui/textarea.tsx';
import { IRequest } from '@/types.ts';
import MedicationList from '../Medications/List.tsx';
import { Button } from '../ui/button.tsx';
import CreateOrderButton from './CreateOrderButton.tsx';
import { PharmaciesUnpaidTable } from './DataTable/PharmaciesUnpaidTable.tsx';
import ProcessingTimes from './ProcessingTimes.tsx';
import SubscriptionCTA from './SubscriptionCTA.tsx';

interface NewRequestProps {
  request: IRequest;
  setView: (view: string) => void;
  save: (data: any) => void;
}
const RequestEdit = ({ request, setView, save }: NewRequestProps) => {
  const hasUnpaidPharmacies =
    request.pharmacies?.filter((pharmacy) => {
      return !request.tasks?.some((task) => task.pharmacy_id === pharmacy.id);
    }) || [];

  return (
    <>
      <div className="flex items-center justify-end gap-2 mb-4">
        {request.tasks?.length != 0 && (
          <Button onClick={() => setView('show')} className="gap-2">
            Results
          </Button>
        )}
        <CreateOrderButton requestId={request.id} />
      </div>

      <div className="flex flex-col gap-12">
        <div className="flex flex-col gap-12 md:gap-8 lg:flex-row">
          <div className="flex-1">
            <MedicationList request={request as IRequest} />

            <div className="flex-1 hidden mt-8 sm:flex">
              <Card className="w-full">
                <CardHeader>
                  <CardTitle>Enter any additional notes</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="w-full">
                    <Textarea
                      className="h-28"
                      placeholder="Please provide any extra details that Needle should know to help us find your medication."
                      defaultValue={request.note}
                      onBlur={(e) => save({ note: e.target.value })}
                    />
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
          <div className="flex-1 w-full sm:flex-row ">
            <Location request={request} />
          </div>
        </div>

        <div className="flex-1 w-full sm:flex-row">
          <PharmaciesUnpaidTable request={request} />
        </div>

        <div className="block sm:hidden ">
          <Card className="w-full">
            <CardHeader>
              <CardTitle>Enter any additional notes</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="w-full">
                <Textarea
                  className="h-28"
                  placeholder="Please provide any extra details that Needle should know to help us find your medication."
                  defaultValue={request.note}
                  onBlur={(e) => save({ note: e.target.value })}
                />
              </div>
            </CardContent>
          </Card>
        </div>

        {hasUnpaidPharmacies.length > 0 && (
          <div className="flex flex-col gap-8 md:flex-row">
            <ProcessingTimes request={request} />
            <RequestSummary request={request} />
          </div>
        )}

        <div>
          <SubscriptionCTA />
        </div>
      </div>
    </>
  );
};

export default RequestEdit;
