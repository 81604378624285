import React, { useState, memo, useEffect } from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { Filter, PlusIcon, Search, Tag, X } from 'lucide-react';
import SubscriptionCTA from '@/components/Requests/SubscriptionCTA';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuCheckboxItem,
} from '@/components/ui/dropdown-menu';
import { Input } from '@/components/ui/input';
import { IMedication } from '@/types';

interface MedicationCardProps {
  medication: IMedication;
  onEdit: (medication: IMedication) => void;
}

const MedicationCard = memo(({ medication, onEdit }: MedicationCardProps) => {
  return (
    <Card>
      <CardHeader className="flex flex-col space-y-1.5 pb-2">
        <div className="flex items-center justify-between">
          <CardTitle className="text-sm font-medium">{medication.medication}</CardTitle>
          <Badge variant={medication.partial_stock_allowed ? 'secondary' : 'outline'}>
            {medication.partial_stock_allowed ? 'Partial OK' : 'Full Stock'}
          </Badge>
        </div>
        {medication.label && (
          <Badge variant="secondary" className="w-fit">
            <Tag className="w-3 h-3 mr-1" />
            {medication.label}
          </Badge>
        )}
      </CardHeader>

      <CardContent>
        <div className="text-2xl font-bold">{medication.dosage}</div>
        <p className="text-xs text-muted-foreground">
          {medication.dosage_form} - {medication.variant}
        </p>
        <div className="flex items-center justify-between mt-2 text-sm">
          <span>Quantity:</span>
          <span>{medication.quantity}</span>
        </div>
        <div className="flex items-center justify-between mt-1 text-sm">
          <span>Preference:</span>
          <span>{medication.preference}</span>
        </div>
      </CardContent>
      <CardFooter className="flex justify-between ">
        <Button variant="outline" className="w-full" onClick={() => onEdit(medication)}>
          <Search className="w-4 h-4 mr-2" />
          View Details
        </Button>
      </CardFooter>
    </Card>
  );
});

const Medications = ({ medications }: { medications: IMedication[] }) => {
  const medicationModal = useModal('medication-modal');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredMedications, setFilteredMedications] = useState(medications || []);
  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);

  const allLabels = [...new Set(medications?.filter((med) => med.label)?.map((med) => med.label))];

  useEffect(() => {
    const filtered = medications?.filter(
      (med) =>
        (med.short_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (med.label && med.label.toLowerCase().includes(searchTerm.toLowerCase()))) &&
        (selectedLabels.length === 0 || (med.label && selectedLabels.includes(med.label))),
    );
    setFilteredMedications(filtered);
  }, [searchTerm, selectedLabels]);

  const handleLabelToggle = (label: string) => {
    setSelectedLabels((prev) => (prev.includes(label) ? prev.filter((l) => l !== label) : [...prev, label]));
  };

  const handleEdit = (medication: IMedication) => {
    medicationModal.show({
      request: null,
      medication: medication,
      medications: medications,
    });
  };

  return (
    <div className="w-full">
      <div className="flex flex-col items-start justify-between gap-4 mb-8 md:flex-row md:items-center">
        <div>
          <h2 className="text-3xl font-bold leading-8 text-neutral-800 sm:text-2xl">
            All of your medications in one place!
          </h2>
          <p className="max-w-sm mt-2 text-sm font-normal leading-normal text-gray-500 md:max-w-none">
            Organize your medication list and keep track of your prescriptions with Needle.
          </p>
        </div>
        <Button
          className="items-center w-full gap-2 transition-all duration-300 rounded-full shadow-sm hover:shadow-md sm:w-auto"
          onClick={() =>
            medicationModal.show({
              medications: filteredMedications,
              defaultPath: 'newMedication',
            })
          }
        >
          <PlusIcon className="w-6 h-6" />
          Add Medication
        </Button>
      </div>

      <div className="flex items-center w-full mb-8 overflow-hidden ">
        <div className="flex items-center gap-2">
          <div className="relative flex-1 md:w-64">
            <Search className="absolute w-4 h-4 transform -translate-y-1/2 left-2 top-1/2 text-muted-foreground" />
            <Input
              className="pl-8 "
              placeholder="Search medications..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="shrink-0">
                <Filter className="w-4 h-4 mr-2" />
                Filter Labels
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuLabel>Select Labels</DropdownMenuLabel>
              <DropdownMenuSeparator />
              {allLabels.map((label) => (
                <DropdownMenuCheckboxItem
                  key={label}
                  checked={selectedLabels.includes(label)}
                  onCheckedChange={() => handleLabelToggle(label)}
                >
                  {label}
                </DropdownMenuCheckboxItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>

      {selectedLabels.length > 0 && (
        <div className="flex flex-wrap gap-2 mb-4">
          {selectedLabels.map((label) => (
            <Badge key={label} variant="secondary" className="px-2 py-1">
              {label}
              <Button
                variant="ghost"
                size="sm"
                className="h-auto p-0 ml-2 text-muted-foreground"
                onClick={() => handleLabelToggle(label)}
              >
                <X className="w-3 h-3" />
                <span className="sr-only">Remove {label} filter</span>
              </Button>
            </Badge>
          ))}
        </div>
      )}

      <div className="grid gap-4 sm:grid-cols-2 ">
        {filteredMedications.map((medication) => (
          <MedicationCard key={medication.id} medication={medication} onEdit={handleEdit} />
        ))}
      </div>

      {!filteredMedications.length && (
        <div className="p-8 mt-8 text-center text-gray-500 rounded-lg shadow-inner bg-gray-50">
          <p className="mb-3 text-xl font-medium">
            {searchTerm.length > 0 ? 'No medications found' : 'You have not added any medications'}
          </p>
          <p className="mb-4 text-sm">
            {searchTerm.length > 0
              ? 'Try adjusting your search terms'
              : 'Click the "Add Medication" button to get started'}
          </p>
          {!searchTerm.length && (
            <Button
              className="mt-2"
              onClick={() =>
                medicationModal.show({
                  medications: filteredMedications,
                  defaultPath: 'newMedication',
                })
              }
            >
              <PlusIcon className="w-5 h-5 mr-2" />
              Add Your First Medication
            </Button>
          )}
        </div>
      )}

      <div className="my-8">
        <SubscriptionCTA />
      </div>
    </div>
  );
};

export default Medications;
