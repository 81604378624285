import NiceModal from '@ebay/nice-modal-react';
import { Star } from 'lucide-react';
import { useQueryClient } from 'react-query';
import { IPharmacy, IRequest } from '@/types';
import axiosClient from '@/utils/axiosClient.ts';

interface FavoriteStarProps {
  request: IRequest;
  pharmacy: IPharmacy;
  pharmacies: IPharmacy[];
}

const FavoriteStar = ({ request, pharmacy, pharmacies }: FavoriteStarProps) => {
  const queryClient = useQueryClient();

  const handleToggleFavorite = (pharmacyId: string) => {
    axiosClient
      .post('/v1/user_favorite_pharmacies/toggle', { pharmacy_id: pharmacyId })
      .then((response) => {
        const updatedPharmacies = pharmacies.map((pharmacy) =>
          pharmacy.id === pharmacyId ? { ...pharmacy, favorited: response.data.favorited } : pharmacy,
        );
        queryClient.setQueryData([`v2/request_pharmacies/${request.id}/finder`], updatedPharmacies);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          NiceModal.show('membership-modal');
        } else {
          console.error('Error toggling favorite:', error);
        }
      });
  };

  return (
    <>
      <button
        onClick={(e) => {
          e.stopPropagation();
          handleToggleFavorite(pharmacy.id);
        }}
        className="flex items-center text-gray-400 transition-colors duration-200 hover:text-yellow-400"
      >
        <Star
          size={18}
          fill={pharmacy.favorited ? '#FFF9C4' : 'none'}
          stroke={pharmacy.favorited ? '#FFD600' : 'currentColor'}
          strokeWidth={1.5}
        />
      </button>
    </>
  );
};

export default FavoriteStar;
