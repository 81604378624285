import { Link } from 'react-router-dom';
import { Button } from '../ui/button.tsx';
import PricingTable from './PricingTable.tsx';

const Settings = () => {
  const url = window.location.pathname;
  const isSuccess = url === '/memberships/success';

  return (
    <div className="space-y-8">
      {isSuccess && (
        <div className="flex flex-col items-center justify-center h-full p-8 mb-10 border rounded-lg bg-brand-green/70 border-brand-green">
          <div className="text-3xl font-bold leading-6 text-brand-dark-blue">Membership Activated!</div>
          <div className="mt-2 font-normal leading-normal text-brand-dark-blue text-md">
            Your membership has been activated successfully. Happy calling!
          </div>
          <Button
            className="mt-6 bg-[#FFDF7C] text-brand-dark-blue font-semibold rounded-full text-lg hover:bg-[#edcd6c] whitespace-normal
            w-full sm:w-auto border "
            asChild
          >
            <Link to="/requests">Start calling pharmacies</Link>
          </Button>
        </div>
      )}

      <PricingTable />
    </div>
  );
};

export default Settings;
