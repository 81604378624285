import { useEffect, useRef } from 'react';
import { createWidget } from '@typeform/embed';
import '@typeform/embed/build/css/widget.css';
import { ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';
import SubscriptionCTA from '@/components/Requests/SubscriptionCTA';
import Section from '@/components/Section';
import useMe from '@/hooks/useMe';

const RequestCall = () => {
  const me = useMe();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (me?.data?.id === undefined) return;
    if (containerRef.current) {
      createWidget('a5HgrafN', {
        container: containerRef.current,
        hidden: {
          full_name: me.data.name,
          email: me.data.email,
          user_id: me.data.id,
          product_id: me.data.plan,
        },
      });
    }
  }, [me.data]);

  return (
    <div className="w-full">
      <div className="flex items-center justify-start gap-1 mt-4 mb-6 sm:mt-2">
        <div className="mr-2 leading-snug text-gray-600 hover:text-violet-600">
          <Link to="/">
            <ArrowLeft />
          </Link>
        </div>
        <div className="font-medium leading-snug text-violet-600">Request Call</div>
      </div>

      <div className="flex flex-col justify-between gap-4 mt-4 mb-8 md:flex-row sm:gap-8">
        <div className="flex flex-col">
          <div className="text-3xl font-bold leading-8 text-neutral-800 sm:text-4xl">Request a Call</div>
          <div className="max-w-4xl mt-2 text-sm font-normal leading-normal text-gray-500 sm:mt-3 md:mt-2 lg:mt-2 xl:mt-2 ">
            Members can request that Needle make other calls on their behalf not just contacting pharmacies. Please
            provide the details below and we will reach out to you.
          </div>
        </div>
      </div>

      <Section>
        <div ref={containerRef} className="h-[calc(100vh-400px)] w-full" />
      </Section>

      <div className="flex flex-col items-start justify-between gap-4 my-8 md:flex-row md:items-center">
        <SubscriptionCTA />
      </div>
    </div>
  );
};

export default RequestCall;
