import { useState } from 'react';
import { ArrowLeft, Search, StarOff, ChevronDown, X } from 'lucide-react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import Loading from '@/components/Loading';
import PharmacyIcon from '@/components/PharmacyIcon';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import useMe from '@/hooks/useMe';
import { useToast } from '@/hooks/useToast';
import { IPharmacy } from '@/types';
import axiosClient from '@/utils/axiosClient';

function MyPharmacies() {
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const currentUser = useMe();

  const { data, isLoading: isPharmaciesLoading } = useQuery(
    ['favoritePharmacies', currentPage, searchQuery, sortOrder],
    () =>
      axiosClient.get('/v1/user_favorite_pharmacies', {
        params: {
          page: currentPage,
          query: searchQuery,
          sort: sortOrder,
        },
      }),
    {
      keepPreviousData: true,
      enabled: currentUser.data?.membership_is_active,
    },
  );

  const toggleFavoriteMutation = useMutation(
    (pharmacyId: string) => axiosClient.post('/v1/user_favorite_pharmacies/toggle', { pharmacy_id: pharmacyId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('favoritePharmacies');
      },
      onError: () => {
        toast({
          title: 'Error',
          description: 'Failed to update favorite status. Please try again.',
          variant: 'destructive',
        });
      },
    },
  );

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handleToggleFavorite = (pharmacyId: string) => {
    toggleFavoriteMutation.mutate(pharmacyId);
  };

  const handleSort = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const pharmacies = data?.data.pharmacies || [];

  return (
    <>
      <div className="flex items-center justify-start gap-1 mt-4 mb-6 sm:mt-2">
        <div className="mr-2 leading-snug text-gray-600 hover:text-violet-600">
          <Link to="/requests">
            <ArrowLeft />
          </Link>
        </div>
        <div className="font-medium leading-snug text-violet-600">My Pharmacies</div>
      </div>

      <div className="flex flex-col justify-between gap-4 mb-8 md:flex-row sm:gap-8">
        <div className="flex flex-col">
          <div className="text-3xl font-bold leading-8 text-neutral-800 sm:text-2xl">
            Manage your favorite pharmacies
          </div>
          <div className="max-w-sm text-sm font-normal leading-normal text-gray-500 md:max-w-none">
            View and manage your favorite pharmacies. You can add or remove pharmacies from your favorites list.
          </div>
        </div>
      </div>

      <div className="inline-flex items-center justify-start w-full gap-1 py-1 pl-4 pr-2 mb-6 bg-white border shadow-sm rounded-2xl border-neutral-200">
        <Search className="w-5 h-5 text-neutral-500" />
        <Input
          type="text"
          placeholder="Search pharmacies"
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
          className="w-full text-sm font-medium placeholder-gray-500 bg-transparent border-none focus-visible:ring-none focus-visible:ring-0 focus-visible:ring-white focus-visible:outline-none focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
        />
        {searchQuery.length > 0 && (
          <Button variant="ghost" onClick={() => handleSearch('')}>
            <X className="w-4 h-4 mr-1" />
          </Button>
        )}
      </div>

      {isPharmaciesLoading ? (
        <Loading text="Loading pharmacies..." />
      ) : (
        <div className="overflow-hidden bg-white rounded-lg shadow-sm">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="w-1/2">
                  <Button variant="ghost" onClick={handleSort} className="font-semibold">
                    Name
                    <ChevronDown className={`ml-2 h-4 w-4 transform ${sortOrder === 'desc' ? 'rotate-180' : ''}`} />
                  </Button>
                </TableHead>
                <TableHead className="w-1/2">Address</TableHead>
                <TableHead className="w-24 text-right">Favorite</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {pharmacies.map((pharmacy: IPharmacy) => (
                <TableRow key={pharmacy.id} className="hover:bg-gray-50">
                  <TableCell className="py-4">
                    <div className="flex items-center space-x-3">
                      <div className="flex-shrink-0 w-8 h-8">
                        <PharmacyIcon pharmacy={pharmacy} />
                      </div>
                      <div className="font-semibold text-gray-900">{pharmacy.name}</div>
                    </div>
                  </TableCell>
                  <TableCell className="text-sm text-gray-500">
                    {pharmacy.address1}, {pharmacy.city}, {pharmacy.state} {pharmacy.zip}
                  </TableCell>
                  <TableCell className="text-right">
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={() => handleToggleFavorite(pharmacy.id)}
                      className={pharmacy.favorited ? 'text-yellow-400' : 'text-gray-400'}
                    >
                      <StarOff className="w-4 h-4" fill={pharmacy.favorited ? 'currentColor' : 'none'} />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}

      {pharmacies.length === 0 && !isPharmaciesLoading && (
        <div className="flex flex-col w-full gap-2 px-2 py-4 text-xs text-center text-gray-600">
          <div>You have not favorited any pharmacies.</div>
          <div>Search for pharmacies and click the star icon to add them to your favorites.</div>
        </div>
      )}
    </>
  );
}

export default MyPharmacies;
