import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useQuery, useQueryClient } from 'react-query';
import Modal from '@/components/Modals/Modal';
import PharmacyResults from '@/components/Requests/PharmacyResults';
import { IMedication, IRequest } from '@/types';

interface MedicationResultsModalProps {
  request: IRequest;
  medication: IMedication;
}

const MedicationResultsModal = NiceModal.create(
  ({ request: initialRequest, medication }: MedicationResultsModalProps) => {
    const modal = useModal('medication-results-modal');
    const queryClient = useQueryClient();

    // useQuery to reactively fetch the latest request data
    const { data: latestRequest } = useQuery<IRequest>(
      [`v1/requests/${initialRequest.id}`],
      () => queryClient.getQueryData<IRequest>([`v1/requests/${initialRequest.id}`]) || initialRequest,
      {
        initialData: initialRequest,
        refetchInterval: 1000,
      },
    );

    return (
      <Modal modal={modal} title={'Pharmacy Availability'} wide>
        <PharmacyResults request={latestRequest || initialRequest} medication={medication} />
      </Modal>
    );
  },
);

export default MedicationResultsModal;
