import { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { ArrowRight, Check } from 'lucide-react';
import posthog from 'posthog-js';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import useBillingPortal from '@/hooks/useBillingPortal';
import useMe from '@/hooks/useMe';
import state from '@/utils/State';
import axiosClient from '@/utils/axiosClient.ts';
import { cn } from '@/utils/utils';
import { Button } from '../ui/button';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

const PricingTable = () => {
  const me = useMe();
  const referral = useRecoilValue(state.referral);

  const navigate = useNavigate();
  const billingPortal = useBillingPortal();

  const [annualPlan, setAnnualPlan] = useState(false);

  const currentPlan = me?.data?.membership_tier || 'free';

  const generateMembershipCheckoutSession = async (selectedPlan: string) => {
    axiosClient
      .post('/v1/memberships', { plan: selectedPlan, duration: annualPlan ? 'annual' : 'monthly' })
      .then((response: any) => {
        if (response?.data?.stripe_session_id) {
          posthog.capture('membership_checkout_started', {
            plan: selectedPlan,
            duration: annualPlan ? 'annual' : 'monthly',
            referral: referral,
          });
          stripePromise.then((stripe) => {
            if (stripe) {
              stripe.redirectToCheckout({
                sessionId: response.data.stripe_session_id,
              });
            }
          });
        }
      });
  };

  const plans = [
    {
      name: 'A la Carte',
      priceMonthly: '$1/call',
      priceYearly: '$1/call',
      description: 'Pay as you go',
      longDescription:
        'Great for one-time medication searches where you have confidence that you’ll be able to find it within a few calls',
      features: ['Use as many as you need', 'Expedited service available', 'Weekend calls', 'Direct Pharmacy Contact'],
      badgeBgColor: 'bg-[#ffa0ff]',
      buttonText: 'Start search',
      buttonAction: () => {
        navigate('/requests');
      },
      selected: currentPlan === 'free',
    },
    {
      name: 'Base',
      priceMonthly: '$16/month',
      priceYearly: '$14/month',
      description: '20 call credits',
      longDescription:
        'Do more with Needle. Call ahead for refills, favorite pharmacies, or use our concierge healthcare service to schedule future care',
      features: [
        'Multiple medications',
        'Call ahead to prep refills',
        '$1 call top-ups as needed',
        'Favorite pharmacies',
      ],
      badgeBgColor: 'bg-[#ffdf7c]',
      buttonText: currentPlan === 'base' ? 'Your plan' : 'Subscribe',
      buttonAction: () => {
        if (currentPlan !== 'base') {
          generateMembershipCheckoutSession('base');
        }
      },
      selected: currentPlan === 'base',
    },
    {
      name: 'Unlimited',
      priceMonthly: '$59/month',
      priceYearly: '$49/month',
      description: 'Use what you need',
      longDescription: 'Forget about counting calls, we will find your prescription in stock, that’s our guarantee',
      features: [
        'Everything in Base',
        'Outcomes based pricing',
        'Refund for failed search',
        'Special customer service line',
      ],
      badgeBgColor: 'bg-[#ffa0ff]',
      buttonText: currentPlan === 'unlimited' ? 'Your plan' : 'Subscribe',
      buttonAction: () => {
        if (currentPlan !== 'unlimited') {
          generateMembershipCheckoutSession('unlimited');
        }
      },
      selected: currentPlan === 'unlimited',
    },
  ];

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>
            <div className="flex items-center justify-between">
              <div className="">Pricing</div>
              <div className=" rounded-full border border-[#e7e7e7] flex items-center w-fit">
                <div
                  className={cn(
                    'px-4 py-2 rounded-full  flex items-center gap-2 cursor-pointer',
                    annualPlan && 'bg-[#8d5bff]',
                  )}
                  onClick={() => setAnnualPlan(true)}
                >
                  <div className="flex items-start justify-start gap-2">
                    <div
                      className={cn('text-sm font-medium leading-snug', annualPlan ? 'text-white' : 'text-[#252525]')}
                    >
                      Yearly billing
                    </div>
                    <div
                      className={cn(
                        'text-sm font-medium leading-snug opacity-75',
                        annualPlan ? 'text-white' : 'text-[#252525]',
                      )}
                    >
                      Save +20%
                    </div>
                  </div>
                </div>
                <div
                  className={cn(
                    'px-4 py-2 rounded-full flex items-center gap-2 cursor-pointer',
                    !annualPlan && 'bg-[#8d5bff]',
                  )}
                  onClick={() => setAnnualPlan(false)}
                >
                  <div className="flex items-start justify-start gap-2">
                    <div
                      className={cn('text-sm font-medium leading-snug', !annualPlan ? 'text-white' : 'text-[#252525]')}
                    >
                      Monthly billing
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex flex-col items-start justify-center">
            <div className="flex flex-col items-start self-stretch justify-start gap-4 p-2 lg:flex-row lg:gap-2">
              {plans.map((plan, index) => (
                <div
                  key={index}
                  className={cn(
                    'flex-grow flex-shrink basis-0 p-6 rounded-lg border flex flex-col justify-between items-start gap-6 cursor-pointer transition-all',
                    plan.selected
                      ? 'border-[#8d5bff] bg-[#fbf9ff]'
                      : 'border-[#eaecf0] bg-white hover:border-[#8d5bff] hover:bg-[#fbf9ff]',
                  )}
                >
                  <div className="flex flex-col items-start justify-start w-full gap-2 min-h-40 lg:min-h-80 xl:min-h-40">
                    <div className="flex flex-col items-center justify-between w-full gap-2 xl:flex-row">
                      <div className="text-[#1d2939] text-2xl font-bold">{plan.name}</div>
                      <div className="bg-[#8d5bff] rounded-full flex items-center">
                        <div className={cn('px-4 py-2 rounded-full flex flex-col items-start', plan.badgeBgColor)}>
                          <div className="text-[#1d2939] text-base font-semibold">
                            {annualPlan ? plan.priceYearly : plan.priceMonthly}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-[#1d2939] text-lg font-bold">{plan.description}</div>
                    <div className="text-[#475467] text-sm">{plan.longDescription}</div>
                  </div>
                  <div
                    className={cn(
                      'w-full h-12 px-4 rounded-full shadow flex justify-center items-center gap-1.5 cursor-pointer',
                      plan.selected ? 'bg-[#48eda9]/25 border border-[#48eda9]' : 'bg-[#8d5bff] text-white',
                    )}
                    onClick={plan.buttonAction ? plan.buttonAction : undefined}
                  >
                    <div className={cn('text-base font-medium', plan.selected ? 'text-[#221a51]' : 'text-white')}>
                      {plan.buttonText}
                    </div>
                  </div>
                  <div className="flex flex-col items-start justify-start w-full gap-2">
                    {plan.features.map((feature, idx) => (
                      <div key={idx} className="flex items-center w-full gap-2">
                        <div className="bg-[#48eda9] rounded-full p-1">
                          <Check className="w-3 h-3" />
                        </div>
                        <div className="text-[#1d2939] text-base">{feature}</div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <div className="self-stretch px-6 pt-2 pb-4 flex justify-between items-center gap-2.5">
              {billingPortal && billingPortal.data && billingPortal.data.url && (
                <Button variant={'link'} asChild>
                  <Link to={billingPortal.data.url} target="_blank" className="flex items-center underline">
                    Manage your billing
                  </Link>
                </Button>
              )}
              <Button variant={'link'} asChild>
                <Link
                  to="https://tfhxazd55h7.typeform.com/to/xpMGKu2V"
                  target="_blank"
                  className="flex items-center underline"
                >
                  Apply for provider-only rates
                  <ArrowRight className="w-4 h-4 ml-1" />
                </Link>
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default PricingTable;
