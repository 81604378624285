import { Copy, Plus } from 'lucide-react';
import posthog from 'posthog-js';
import { BiCaretDown } from 'react-icons/bi';
import { useNavigate } from 'react-router';
import axiosClient from '@/utils/axiosClient';
import { Button } from '../ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';

interface CreateOrderButtonProps {
  requestId: string;
}

const CreateOrderButton = ({ requestId }: CreateOrderButtonProps) => {
  const navigate = useNavigate();
  const handleClone = () => {
    posthog.capture('clone_search');
    axiosClient.post(`/v1/requests?last_request_id=${requestId}`).then((response) => {
      navigate(`/requests/${response.data.id}`);
    });
  };

  const handleFresh = () => {
    posthog.capture('new_search_created');
    axiosClient.post(`/v1/requests?fresh=true`).then((response) => {
      navigate(`/requests/${response.data.id}`);
    });
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <Button className="flex gap-2 ">
          Start New Search
          <BiCaretDown className="w-4 h-4 " />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel>Options</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={handleClone}>
          <Copy className="w-4 h-4 mr-2" />
          Clone this search
        </DropdownMenuItem>
        <DropdownMenuItem onClick={handleFresh}>
          <Plus className="w-4 h-4 mr-2" />
          Create from scratch
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default CreateOrderButton;
