import { useState } from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { PencilIcon } from 'lucide-react';
import { AiOutlineLoading } from 'react-icons/ai';
import { BsTrash } from 'react-icons/bs';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog.tsx';
import { Button } from '@/components/ui/button.tsx';
import { IMedication, IRequest } from '@/types.ts';
import axiosClient from '@/utils/axiosClient.ts';
import MedIcon from '../MedIcon';
import { TableCell, TableRow } from '../ui/table';

interface ListItemProps {
  medication: IMedication;
  request: IRequest;
}
const ListItem = ({ medication, request }: ListItemProps) => {
  const medicationModal = useModal('medication-modal');
  const [isSaving, setIsSaving] = useState(false);

  const archive = async () => {
    setIsSaving(true);
    axiosClient
      .delete(`/v1/medication_request/${medication.id}/${request.id}`)
      .then((response) => {
        if (response.status === 200) {
          window.location.reload();
        }
        setIsSaving(false);
      })
      .catch((error) => {
        console.error('Error:', error);
        setIsSaving(false);
      });
  };

  return (
    <TableRow className=" text-pretty hover:bg-gray-100">
      <TableCell className="flex flex-row items-center gap-2 ">
        <MedIcon medication={medication} />
        <div className="flex flex-col gap-1 text-base text-gray-900 ">
          <div className="font-semibold">{medication.medication}</div>
          <div className="text-xs text-gray-500 truncate line-clamp-1">
            {medication.variant}{' '}
            {medication.label && (
              <>
                for{' '}
                <span className="text-gray-900 underline decoration-dotted decoration-purple-800">
                  {' '}
                  {medication.label}
                </span>
              </>
            )}
          </div>
        </div>
      </TableCell>

      <TableCell className="">
        <div className="flex flex-row justify-end gap-1">
          <Button
            variant="ghost"
            size={'icon'}
            onClick={() =>
              medicationModal.show({
                request: request,
                medication: medication,
                medications: request.medications,
              })
            }
          >
            <PencilIcon className="w-4 h-4" />
          </Button>

          {!request.tasks.length && (
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <Button variant="ghost" size={'icon'}>
                  {isSaving ? <AiOutlineLoading className="w-4 h-4 animate-spin" /> : <BsTrash className="w-4 h-4" />}
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                  <AlertDialogDescription>
                    This will remove the medication from the search. The medication can be added back later as it will
                    be in your account. This will remove the medication from the search. The medication can be added
                    back later as it will be in your account.
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                  <AlertDialogAction onClick={archive}>Remove</AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};

export default ListItem;
