import { IBillingPortal } from '@/types.ts';
import { getUrl } from '@/utils/api';

function useBillingPortal() {
  const query = getUrl(`v1/stripe/portal`);
  return {
    ...query,
    data: query.data as IBillingPortal,
  };
}

export default useBillingPortal;
