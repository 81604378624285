import { Popover, PopoverTrigger, PopoverContent } from './ui/popover';

type StatusDetails = {
  title: string;
  text: string;
  status: string;
};

interface StatusBadgeProps {
  statusDetails: StatusDetails;
}

const StatusBadge: React.FC<StatusBadgeProps> = ({ statusDetails }) => {
  return (
    <>
      <Popover>
        <PopoverTrigger>
          <div className="px-3 py-1 text-xs font-medium border border-purple-700 cursor-pointer text-balance hover:underline rounded-xl hover:shadow">
            {statusDetails.status}
          </div>
        </PopoverTrigger>
        <PopoverContent side="bottom" className="p-2 text-sm text-white bg-gray-800 rounded-lg shadow-lg">
          <div className="mb-1 text-md">{statusDetails.title}</div>
          <div>{statusDetails.text}</div>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default StatusBadge;
