import { Infinity, ShoppingCart } from 'lucide-react';
import posthog from 'posthog-js';
import { useNavigate } from 'react-router';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import useMe from '@/hooks/useMe';
import { Button } from '../ui/button';

const SubscriptionCTA = () => {
  const navigate = useNavigate();
  const currentUser = useMe();

  if (currentUser?.data?.membership_tier == 'unlimited') {
    return null;
  }

  return (
    <>
      <Card className="text-white bg-gradient-to-br from-[#8D5BFF] to-purple-300">
        <CardHeader>
          <CardTitle className="flex items-center text-2xl font-bold">
            <Infinity className="w-6 h-6 mr-2" />
            Unlimited Membership
          </CardTitle>
          <CardDescription className="text-white/90">
            Get unlimited calls with our premium package. You will find your medication in stock. Guaranteed.
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
          <ul className="space-y-2 list-disc list-inside">
            <li>$59/month for unlimited searches</li>
            <li>Unlimited medications</li>
            <li>We search until we find your medication in stock</li>
            <li>100% Money-back guarantee</li>
          </ul>
          <Button
            className="w-full text-black bg-yellow-300 hover:bg-blue-100"
            onClick={() => {
              posthog.capture('membership_cta_clicked');
              navigate('/memberships');
            }}
          >
            <ShoppingCart className="w-4 h-4 mr-2" />
            Subscribe Now
          </Button>
        </CardContent>
      </Card>
    </>
  );
};

export default SubscriptionCTA;
