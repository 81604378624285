import { IAppointment } from '@/types.ts';
import { getUrl } from '@/utils/api';

function useAppointments() {
  const query = getUrl(`v2/appointments`);
  return {
    ...query,
    data: query.data as IAppointment[],
  };
}

export default useAppointments;
