import { Pill, Search, Store } from 'lucide-react';
import { FaUserDoctor } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import { IRequest } from '@/types.ts';
import StatusBadge from '../StatusBadge';
import Time from '../Time';
import { Button } from '../ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '../ui/card';

const ListItem = ({ request }: { request: IRequest; index: number }) => {
  return (
    <>
      <Card key={request.id} className="flex flex-col hover:border-purple-400">
        <CardHeader className="pb-2">
          <div className="flex items-center justify-between">
            <CardTitle>
              <Link to={`/requests/${request.id}`} className="flex items-center gap-2 text-lg font-medium">
                <Search size={16} />
                <div>{`Rx-${request.id.slice(0, 6).toUpperCase()}`}</div>
              </Link>
            </CardTitle>
            <StatusBadge statusDetails={request.status_details} />
          </div>
          <CardDescription>
            <Time>{request.created_at}</Time>
          </CardDescription>
        </CardHeader>
        <CardContent className="flex-grow pt-4">
          {request.account && (
            <div className="flex items-center mb-2 text-sm" title={`This was prescribed by ${request.account.name}`}>
              <FaUserDoctor className="w-4 h-4 mr-1" />
              <span>{request.account.name}</span>
            </div>
          )}
          <div className="flex justify-between mb-3 text-sm">
            <div className="flex items-center">
              <Pill className="w-4 h-4 mr-1" />
              <span>
                {request.medications.length} Medication{request.medications.length !== 1 ? 's' : ''}
              </span>
            </div>
            <div className="flex items-center">
              <Store className="w-4 h-4 mr-1" />
              <span>
                {request.pharmacies.length} Pharmac{request.pharmacies.length !== 1 ? 'ies' : 'y'}
              </span>
            </div>
          </div>
          {request.medications.map((medication) => (
            <div key={medication.id} className="flex items-center gap-2 text-sm font-medium">
              <span>{medication.short_name}</span>
            </div>
          ))}
          <p className="mt-2 text-xs text-muted-foreground line-clamp-3">{request.status_details.text}</p>
        </CardContent>
        <CardFooter className="pt-2">
          <Button size="sm" className="w-full" asChild>
            <Link to={`/requests/${request.id}`} className="w-full gap-2">
              <Search size={16} />
              View Details
            </Link>
          </Button>
        </CardFooter>
      </Card>
    </>
  );
};

export default ListItem;
