import { ArrowLeft, Calendar, Clock, MapPin } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import useAppointments from '@/hooks/useAppointments';

const Appointments = () => {
  const appointments = useAppointments();

  return (
    <div>
      <div className="flex items-center justify-start gap-1 mt-4 mb-6 sm:mt-2 ">
        <div className="mr-2 leading-snug text-gray-600 hover:text-violet-600">
          <Link to="/">
            <ArrowLeft />
          </Link>
        </div>
        <div className="font-medium leading-snug text-violet-600">My Appointments</div>
      </div>

      <div className="flex flex-col gap-6 lg:flex-row">
        <Card className="flex-1 max-w-xl">
          {' '}
          <CardHeader>
            <CardTitle>Appointment Setup</CardTitle>
            <CardDescription>
              Add appointments to our app by inviting appointments@findneedle.co to your calendar events.
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              <h3 className="text-lg font-semibold">How to set up:</h3>
              <ol className="space-y-2 list-decimal list-inside">
                <li>Open your calendar app (Google Calendar, Outlook, Apple Calendar, etc.)</li>
                <li>Create a new event or edit an existing one</li>
                <li>
                  Add <span className="px-2 py-1 font-mono bg-purple-200 rounded-md">appointments@findneedle.co</span>{' '}
                  as an attendee
                </li>
                <li>Save or send the invite</li>
              </ol>
              <hr />
              <div className="mt-12">
                <h4 className="mb-2 font-semibold">Detailed tutorials:</h4>
                <Tabs defaultValue="google">
                  <TabsList className="grid w-full grid-cols-3">
                    <TabsTrigger value="google">Google Calendar</TabsTrigger>
                    <TabsTrigger value="outlook">Outlook</TabsTrigger>
                    <TabsTrigger value="apple">Apple Calendar</TabsTrigger>
                  </TabsList>
                  <TabsContent value="google" className="mt-4">
                    <h5 className="mb-2 font-semibold">Google Calendar Tutorial</h5>
                    <ol className="space-y-2 list-decimal list-inside">
                      <li>
                        Go to{' '}
                        <a href="https://calendar.google.com" className="text-blue-600 hover:underline">
                          Google Calendar
                        </a>
                      </li>
                      <li>Click the "Create" button or select a time slot on the calendar</li>
                      <li>In the event details, find the "Add guests" field</li>
                      <li>
                        Type{' '}
                        <span className="px-2 py-1 font-mono bg-purple-200 rounded-md">appointments@findneedle.co</span>{' '}
                        and press Enter
                      </li>
                      <li>Fill in other event details as needed</li>
                      <li>Click "Save" at the top of the event window</li>
                      <li>If prompted, click "Send" to send the invitation</li>
                    </ol>
                    <p className="mt-2">Your appointment will now sync with our app!</p>
                  </TabsContent>
                  <TabsContent value="outlook" className="mt-4">
                    <h5 className="mb-2 font-semibold">Outlook Tutorial</h5>
                    <ol className="space-y-2 list-decimal list-inside">
                      <li>Open Outlook and go to the Calendar view</li>
                      <li>Click "New Event" or double-click a time slot</li>
                      <li>
                        In the "To" field, type{' '}
                        <span className="px-2 py-1 font-mono bg-purple-200 rounded-md">appointments@findneedle.co</span>
                      </li>
                      <li>Fill in the event title, location, and other details</li>
                      <li>Click "Send" at the top of the window</li>
                    </ol>
                    <p className="mt-2">For Outlook Web App:</p>
                    <ol className="space-y-2 list-decimal list-inside">
                      <li>
                        Go to{' '}
                        <a href="https://outlook.office.com/calendar" className="text-blue-600 hover:underline">
                          Outlook Calendar
                        </a>
                      </li>
                      <li>Click "New event" or select a time slot</li>
                      <li>
                        Click "Add attendees" and enter{' '}
                        <span className="px-2 py-1 font-mono bg-purple-200 rounded-md">appointments@findneedle.co</span>
                      </li>
                      <li>Fill in other event details and click "Send"</li>
                    </ol>
                    <p className="mt-2">Your appointment will now sync with our app!</p>
                  </TabsContent>
                  <TabsContent value="apple" className="mt-4">
                    <h5 className="mb-2 font-semibold">Apple Calendar Tutorial</h5>
                    <p className="mb-2">On Mac:</p>
                    <ol className="space-y-2 list-decimal list-inside">
                      <li>Open the Calendar app</li>
                      <li>Double-click a time slot or click the "+" button to create a new event</li>
                      <li>
                        In the "Invitees" field, type{' '}
                        <span className="px-2 py-1 font-mono bg-purple-200 rounded-md">appointments@findneedle.co</span>
                      </li>
                      <li>Fill in other event details</li>
                      <li>Click "Send" to send the invitation</li>
                    </ol>
                    <p className="mt-2">On iPhone or iPad:</p>
                    <ol className="space-y-2 list-decimal list-inside">
                      <li>Open the Calendar app</li>
                      <li>Tap "+" to create a new event</li>
                      <li>
                        Tap "Invitees" and enter{' '}
                        <span className="px-2 py-1 font-mono bg-purple-200 rounded-md">appointments@findneedle.co</span>
                      </li>
                      <li>Fill in other event details</li>
                      <li>Tap "Add" in the top right corner</li>
                      <li>Tap "Send" to send the invitation</li>
                    </ol>
                    <p className="mt-2">Your appointment will now sync with our app!</p>
                  </TabsContent>
                </Tabs>
              </div>
            </div>
          </CardContent>
        </Card>

        <Card className="flex-1">
          <CardHeader>
            <CardTitle>Your Appointments</CardTitle>
            <CardDescription>Here are your upcoming events synced from your calendar.</CardDescription>
          </CardHeader>
          <CardContent>
            <div className="space-y-6">
              {appointments.data?.map((appointment) => (
                <div key={appointment.id} className="pb-4 border-b last:border-b-0">
                  <h3 className="mb-2 text-lg font-semibold">{appointment.summary}</h3>
                  <div className="space-y-2 text-sm">
                    <p className="flex items-center">
                      <Calendar className="w-4 h-4 mr-2" />
                      {new Date(appointment.start_time).toLocaleString()} -{' '}
                      {new Date(appointment.end_time).toLocaleTimeString()}
                    </p>
                    <p className="flex items-center">
                      <MapPin className="w-4 h-4 mr-2" />
                      {appointment.location || 'No location specified'}
                    </p>
                    <p className="flex items-center">
                      <Clock className="w-4 h-4 mr-2" />
                      Status: {appointment.status}
                    </p>
                    {appointment.description && <p className="mt-2">{appointment.description}</p>}
                  </div>
                </div>
              ))}

              {appointments.data?.length == 0 && (
                <div>
                  No events found. Please make sure you have added the email to your calendar invites. Updates every 5
                  minutes.
                </div>
              )}
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default Appointments;
