import { useState } from 'react';
import { IRequest } from '@/types.ts';
import axiosClient from '@/utils/axiosClient.ts';
import RequestEdit from './RequestEdit.tsx';
import RequestShow from './RequestShow.tsx';

interface NewRequestProps {
  request: IRequest;
}
const Request = ({ request }: NewRequestProps) => {
  const save = (data: any) => {
    axiosClient.put(`/v1/requests/${request.id}`, data).then((response) => {
      request = response.data;
    });
  };

  const [view, setView] = useState(request.tasks?.length == 0 ? 'edit' : 'show');
  const RequestComponent = view == 'edit' ? RequestEdit : RequestShow;

  return <RequestComponent request={request} save={save} setView={setView} />;
};

export default Request;
