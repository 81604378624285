import Login from '@/components/External/Login.tsx';
import ExternalWrapper from '@/components/ExternalWrapper.tsx';
import Layout from '@/components/Layout.tsx';
import Orders from '../components/Requests/List.tsx';
import { useAuth } from '../providers/AuthProvider';

const Application = () => {
  const auth = useAuth();

  if (!auth) {
    return (
      <ExternalWrapper>
        <Login />
      </ExternalWrapper>
    );
  }

  return (
    <Layout>
      <Orders />
    </Layout>
  );
};

export default Application;
