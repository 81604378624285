import { ArchiveIcon } from 'lucide-react';
import { useQueryClient } from 'react-query';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog.tsx';
import { IRequest } from '@/types';
import axiosClient from '@/utils/axiosClient.ts';
import { Button } from '../ui/button';

const Archive = ({ requestId }: { requestId: string }) => {
  const queryClient = useQueryClient();

  const archive = async () => {
    axiosClient
      .delete(`/v1/requests/${requestId}`)
      .then((response) => {
        if (response.status === 200) {
          queryClient.setQueryData([`v1/requests`], (oldData: any) => {
            return oldData.filter((item: IRequest) => item.id !== requestId);
          });
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button variant="outline" className="w-full text-xs ">
          <ArchiveIcon className="h-4 text-red-600" /> Archive Search
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This will archive this search. You will not be able to undo this action.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={archive}>Remove</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default Archive;
