import { cn } from '@/utils/utils';

interface SectionProps {
  title?: string | React.ReactNode;
  rightSide?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  description?: string;
}

const Section = ({ title, description, rightSide, className, children }: SectionProps) => {
  return (
    <>
      <div className={cn('flex flex-col w-full bg-white ', className)}>
        <div className="flex flex-col">
          {title && (
            <div className="flex flex-col gap-4 py-2 sm:px-4 sm:py-3 h-13">
              <div className="inline-flex items-center self-stretch justify-start">
                <div className="text-base font-semibold leading-normal grow shrink basis-0 text-neutral-800">
                  {title}

                  {description && (
                    <div className="hidden text-sm font-normal leading-normal grow shrink basis-0 text-neutral-500 sm:block">
                      {description}
                    </div>
                  )}
                </div>
                {rightSide}
              </div>
            </div>
          )}
          <div className="flex flex-col justify-start w-full h-auto gap-2 pt-0 sm:p-2">{children}</div>
        </div>
      </div>
    </>
  );
};

export default Section;
