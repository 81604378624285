import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import state from '@/utils/State';

declare global {
  interface Window {
    rewardful: any;
    Rewardful: any;
  }
}

const Rewardful = () => {
  const setReferral = useSetRecoilState(state.referral);

  useEffect(() => {
    window.rewardful('ready', () => {
      if (window.rewardful && window.rewardful.referral) {
        console.log('Rewardful ready');
        setReferral(window.rewardful.referral);
      }
    });
  }, []);
  return null;
};

export default Rewardful;
