import { useModal } from '@ebay/nice-modal-react';
import { Calendar, ChevronsUpDown, DollarSign, LogOut, SearchIcon, Send, ShoppingCart, StoreIcon } from 'lucide-react';
import { BsCapsulePill } from 'react-icons/bs';
import { VscAccount } from 'react-icons/vsc';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '@/assets/logo-pin.svg';
import LogoWord from '@/assets/needle-word.svg';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import {
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from '@/components/ui/sidebar';
import { IUser } from '@/types';
import { USDollar } from '@/utils/helpers';

interface AppSidebarProps {
  currentUser: IUser;
}

const AppSidebar = ({ currentUser }: AppSidebarProps) => {
  const { isMobile } = useSidebar();
  const membershipModal = useModal('membership-modal');

  const navigate = useNavigate();

  const handleLogout = () => {
    navigate('/logout');
  };

  const firstInitial = currentUser.first_name ? currentUser.first_name.charAt(0).toUpperCase() : '';
  const lastInitial = currentUser.last_name ? currentUser.last_name.charAt(0).toUpperCase() : '';
  const initials = `${firstInitial}${lastInitial}`;

  return (
    <>
      <SidebarHeader>
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton
              tooltip={{
                children: 'Needle',
              }}
              asChild
            >
              <Link to={'/'} className="flex items-center gap-2">
                <img src={Logo} alt="Needle" className="w-6" />
                <img src={LogoWord} alt="Needle" className="w-24" />
              </Link>
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>

      <SidebarContent>
        <SidebarGroup>
          <SidebarMenu>
            <SidebarMenuItem>
              <SidebarMenuButton
                isActive={window.location.pathname === '/requests' || window.location.pathname === '/requests/new'}
                asChild
                tooltip={{
                  children: 'Rx Searches',
                }}
              >
                <Link to={'/requests'}>
                  <SearchIcon />
                  <span>Rx Searches</span>
                </Link>
              </SidebarMenuButton>
            </SidebarMenuItem>
            <SidebarMenuItem>
              <SidebarMenuButton
                isActive={
                  window.location.pathname === '/medications' || window.location.pathname === '/medications/new'
                }
                asChild
                tooltip={{
                  children: 'Medications',
                }}
              >
                <Link to={'/medications'}>
                  <BsCapsulePill />
                  <span>Medications</span>
                </Link>
              </SidebarMenuButton>
            </SidebarMenuItem>
            <SidebarMenuItem>
              <SidebarMenuButton
                asChild
                isActive={window.location.pathname === '/my-pharmacies'}
                tooltip={{
                  children: 'Pharmacies',
                }}
              >
                {currentUser.membership_tier === 'free' ? (
                  <button
                    onClick={() => {
                      membershipModal.show();
                    }}
                  >
                    <StoreIcon />
                    <span>Pharmacies</span>
                  </button>
                ) : (
                  <Link to={'/my-pharmacies'}>
                    <StoreIcon />
                    <span>Pharmacies</span>
                  </Link>
                )}
              </SidebarMenuButton>
            </SidebarMenuItem>
            <SidebarMenuItem>
              <SidebarMenuButton
                asChild
                isActive={window.location.pathname === '/appointments'}
                tooltip={{
                  children: 'Appointments',
                }}
              >
                {currentUser.membership_tier === 'free' ? (
                  <button
                    onClick={() => {
                      membershipModal.show();
                    }}
                  >
                    <Calendar />
                    <span>Appointments</span>
                  </button>
                ) : (
                  <Link to={'/appointments'}>
                    <Calendar />
                    <span>Appointments</span>
                  </Link>
                )}
              </SidebarMenuButton>
            </SidebarMenuItem>
            <SidebarMenuItem>
              <SidebarMenuButton
                asChild
                isActive={window.location.pathname === '/memberships'}
                tooltip={{
                  children: 'Membership',
                }}
              >
                <Link to={'/memberships'}>
                  <ShoppingCart />
                  <span>Pricing</span>
                </Link>
              </SidebarMenuButton>
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarGroup>
        <div className="mt-auto">
          <SidebarGroup>
            <SidebarGroupContent>
              <SidebarMenu>
                <SidebarMenuItem>
                  <SidebarMenuButton
                    tooltip={{
                      children: 'Give feedback',
                    }}
                    size="sm"
                    asChild
                  >
                    <Link
                      to={`https://tfhxazd55h7.typeform.com/to/IS730n0z#user_id=${currentUser.id}&email=${currentUser.email}`}
                      target={'_blank'}
                    >
                      <Send />
                      <span>Feedback</span>
                    </Link>
                  </SidebarMenuButton>
                </SidebarMenuItem>
                <SidebarMenuItem>
                  <SidebarMenuButton
                    tooltip={{
                      children: (
                        <>
                          {currentUser.membership_tier === 'unlimited'
                            ? 'Unlimited Plan'
                            : 'Credit Balance: ' + USDollar.format(currentUser.total_credits)}
                        </>
                      ),
                    }}
                    size="sm"
                    asChild
                  >
                    <Link to={'/memberships'}>
                      <DollarSign />
                      <span>
                        {currentUser.membership_tier === 'unlimited'
                          ? 'Unlimited Plan'
                          : 'Credit Balance: ' + USDollar.format(currentUser.total_credits)}
                      </span>{' '}
                    </Link>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              </SidebarMenu>
            </SidebarGroupContent>
          </SidebarGroup>
        </div>
      </SidebarContent>

      <SidebarFooter>
        <SidebarMenu>
          <SidebarMenuItem>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <SidebarMenuButton
                  size="lg"
                  className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
                >
                  <Avatar className="w-8 h-8 rounded-lg">
                    <AvatarImage src={currentUser.picture} alt={currentUser.name} />
                    <AvatarFallback className="rounded-lg">{initials}</AvatarFallback>
                  </Avatar>
                  <div className="grid flex-1 text-sm leading-tight text-left">
                    <span className="font-semibold truncate">{currentUser.name}</span>
                    <span className="text-xs truncate">{currentUser.email}</span>
                  </div>
                  <ChevronsUpDown className="ml-auto size-4" />
                </SidebarMenuButton>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
                side={isMobile ? 'bottom' : 'right'}
                align="end"
                sideOffset={4}
              >
                <DropdownMenuLabel className="p-0 font-normal">
                  <div className="flex items-center gap-2 px-1 py-1.5 text-left text-sm">
                    <Avatar className="w-8 h-8 rounded-lg">
                      <AvatarImage src={currentUser.picture} alt={currentUser.name} />
                      <AvatarFallback className="rounded-lg">{initials}</AvatarFallback>
                    </Avatar>
                    <div className="grid flex-1 text-sm leading-tight text-left">
                      <span className="font-semibold truncate">{currentUser.name}</span>
                      <span className="text-xs truncate">{currentUser.email}</span>
                    </div>
                  </div>
                </DropdownMenuLabel>
                <DropdownMenuSeparator />

                <DropdownMenuSeparator />
                <DropdownMenuGroup>
                  <DropdownMenuItem asChild>
                    <Link to={'/memberships'}>
                      <VscAccount />
                      <span>Membership</span>
                    </Link>
                  </DropdownMenuItem>
                </DropdownMenuGroup>
                <DropdownMenuSeparator />
                <DropdownMenuItem asChild>
                  <AlertDialog>
                    <AlertDialogTrigger className="w-full">
                      <div className="flex gap-2 pt-1 pl-2.5 text-sm w-full">
                        <LogOut size={'16'} />
                        Log out
                      </div>
                    </AlertDialogTrigger>
                    <AlertDialogContent>
                      <AlertDialogHeader>
                        <AlertDialogTitle>Are you sure you want to logout?</AlertDialogTitle>
                        <AlertDialogDescription>
                          We will log you out of the application at this time.
                        </AlertDialogDescription>
                      </AlertDialogHeader>
                      <AlertDialogFooter>
                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                        <AlertDialogAction onClick={handleLogout}>Logout</AlertDialogAction>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialog>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarFooter>
    </>
  );
};

export default AppSidebar;
