import { useModal } from '@ebay/nice-modal-react';
import { PlusIcon } from 'lucide-react';
import NoMed from '@/assets/hero-no-meds.svg';
import ListItem from '@/components/Medications/ListItem';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import useMe from '@/hooks/useMe';
import useMedications from '@/hooks/useMedications';
import { IRequest } from '@/types';
import { Table, TableBody } from '../ui/table';

interface ListProps {
  request: IRequest;
}

const List = ({ request }: ListProps) => {
  const me = useMe();
  const currentPlan = me?.data?.membership_tier || 'free';
  const medications = useMedications();
  const medicationModal = useModal('medication-modal');
  const membershipModal = useModal('membership-modal');

  const isMedicationInTasks = request.tasks?.length;

  const sortedMedications = request.medications?.sort((a, b) => a.medication.localeCompare(b.medication)) || [];

  return (
    <Card>
      <CardHeader>
        <div className="flex items-center justify-between">
          <CardTitle>Medications</CardTitle>
          <div>
            {!isMedicationInTasks && (
              <div className="flex justify-end">
                <Button
                  className="h-8 gap-2 text-sm rounded-full whitespace-nowrap"
                  onClick={() => {
                    if (sortedMedications.length > 0 && currentPlan === 'free') {
                      membershipModal.show();
                    } else {
                      medicationModal.show({ request, medications: medications?.data });
                    }
                  }}
                  disabled={medications?.isLoading}
                >
                  <PlusIcon className="w-4 h-4" />
                  Add Medication
                </Button>
              </div>
            )}
          </div>
        </div>
      </CardHeader>
      <CardContent>
        <div className="w-full overflow-x-scroll border border-gray-300 rounded-xl">
          <Table className="w-full text-xs text-gray-600">
            <TableBody>
              {sortedMedications.map((medication) => (
                <ListItem key={medication.id} medication={medication} request={request} />
              ))}
            </TableBody>
          </Table>
          {!sortedMedications.length && (
            <div className="p-4 text-lg text-center text-gray-600">
              <p className="mb-4">You have not added any medications</p>
              <button onClick={() => medicationModal.show({ request, medications: medications?.data })}>
                <img src={NoMed} alt="No Medications" className="mx-auto " />
              </button>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default List;
