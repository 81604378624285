import { useState } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { MdOutlineClear } from 'react-icons/md';
import { useQueryClient } from 'react-query';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input.tsx';
import { useToast } from '@/hooks/use-toast';
import usePharmacyFinder from '@/hooks/usePharmacyFinder';
import { IRequest } from '@/types.ts';
import axiosClient from '@/utils/axiosClient.ts';

interface LocationModalProps {
  request: IRequest;
}
const LocationInput = ({ request }: LocationModalProps) => {
  const pharmacyFinder = usePharmacyFinder(request);
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const [address, setAddress] = useState<string>(request.location?.address || '');
  const [isAddressSelected, setIsAddressSelected] = useState(false); // New state variable

  const { placePredictions, getPlacePredictions } = usePlacesService({
    apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
  });

  const handleAddressSelection = (givenAddress: any) => {
    const payload = {
      address: givenAddress.description,
      google_place_id: givenAddress.place_id,
      request_id: request.id,
    };

    axiosClient
      .post(`/v1/locations`, payload)
      .then((response) => {
        setAddress(givenAddress.description);
        setIsAddressSelected(true); // Update state here
        queryClient.setQueryData([`v1/requests/${request.id}`], response.data);
        pharmacyFinder.refetch();
        toast({
          title: 'Success',
          description: 'Location updated successfully.',
        });
      })
      .catch(() => {
        toast({
          title: 'Error',
          description: 'There was an error updating your location.',
        });
      });
  };

  const showDropdown = !isAddressSelected && address !== '' && placePredictions.length > 0; // Modified condition

  const clearAddress = () => {
    setAddress('');
    setIsAddressSelected(false); // Reset state when clearing address
  };

  return (
    <>
      <div className="relative w-full">
        <div className="">
          <Input
            type="text"
            id="address"
            autoComplete="off"
            placeholder="Begin typing your address..."
            value={address}
            className="w-full text-sm truncate bg-transparent border-none focus:ring-0 focus:border-0 focus:outline-none"
            onChange={(evt) => {
              const newAddress = evt.target.value;
              setAddress(newAddress);
              setIsAddressSelected(false); // Reset state here

              if (newAddress.length > 0) {
                getPlacePredictions({
                  input: newAddress,
                  types: ['address'],
                });
              }
            }}
          />
        </div>

        {showDropdown && (
          <div className="absolute z-[9999] bg-white flex flex-col w-full border border-gray-700 divide-y rounded-md divide-primary">
            {placePredictions.map((item) => {
              return (
                <div
                  key={item.description}
                  className="px-2 py-2 text-xs text-gray-700 cursor-pointer hover:bg-primary w-fill hover:text-white "
                  onClick={() => handleAddressSelection(item)}
                >
                  {item.description}
                </div>
              );
            })}
          </div>
        )}
      </div>
      {address.length > 0 && (
        <Button title={'Clear address'} onClick={clearAddress} className="px-2" variant="ghost">
          <MdOutlineClear className="w-5 h-5" />
        </Button>
      )}
    </>
  );
};

export default LocationInput;
