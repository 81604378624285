import * as z from 'zod';

export const smsSubscribeSchema = z.object({
  phone: z
    .string()
    .min(10, { message: 'Phone must be at least 10 digits.' })
    .regex(/^\(\d{3}\) \d{3}-\d{4}$/, { message: 'Phone is not valid.' }),
});

export type SMSSubscribeFormValues = z.infer<typeof smsSubscribeSchema>;
