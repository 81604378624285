import { PlusIcon } from '@heroicons/react/24/outline';
import { Search } from 'lucide-react';
import { Link } from 'react-router-dom';
import Loading from '@/components/Loading';
import { Button } from '@/components/ui/button';
import useRequests from '@/hooks/useRequests.ts';
import ListItem from './ListItem';
import SubscriptionCTA from './SubscriptionCTA';

const List = () => {
  const requests = useRequests();

  return (
    <>
      <div className="flex flex-col-reverse justify-between gap-8 mb-8 sm:flex-col sm:gap-4 md:flex-row">
        <div className="flex flex-col">
          <div className="flex items-center gap-2 text-3xl font-bold leading-8 text-neutral-800 sm:text-2xl text-pretty">
            <Search className="w-6 h-6 mr-2" />
            Rx Searches
          </div>
        </div>
        <div className="flex items-center justify-end">
          <Button className="w-full gap-1 rounded-full sm:w-auto whitespace-nowrap" asChild>
            <Link to="/requests/new">
              <PlusIcon className="w-5 h-5" />
              Start New Search
            </Link>
          </Button>
        </div>
      </div>

      <div className="grid gap-4 sm:grid-cols-2">
        {requests?.data?.map((request, index) => <ListItem key={request.id} request={request} index={index} />)}
      </div>

      {requests.isLoading && (
        <div className="flex justify-center w-full mb-8">
          <Loading />
        </div>
      )}

      {requests?.data?.length === 0 && (
        <div className="flex flex-col w-full gap-2 px-2 py-4 text-xs text-center text-gray-600">
          <div>You have not created an search.</div>
          <div>Click the button above to create one.</div>
        </div>
      )}

      <div className="my-8">
        <SubscriptionCTA />
      </div>
    </>
  );
};

export default List;
