import React from 'react';
import Loading from '@/components/Loading';
import useMedications from '@/hooks/useMedications';
import Medications from './Medications';

const MedicationsLoader = () => {
  const medications = useMedications();

  if (medications?.isLoading || !medications?.data) {
    return <Loading />;
  }

  return <Medications medications={medications.data} />;
};

export default MedicationsLoader;
